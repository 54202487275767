import translate from '../../Translations/index';
import cardIcon from '../../../Public/Images/AnimatedIcons/card.gif'; 
import cleanHome from '../../../Public/Images/AnimatedIcons/clean_home.gif';
import privateGuided from '../../../Public/Images/AnimatedIcons/private_guided.gif'; 
import sidrisFamily from '../../../Public/Images/AnimatedIcons/sidris_family.gif'; 
import together from '../../../Public/Images/AnimatedIcons/together.gif'; 
import web from '../../../Public/Images/AnimatedIcons/web.gif'; 
import becomeTherapist from '../../../Public/Images/AnimatedIcons/become_therapist.gif';
import astralTraveling from '../../../Public/Images/AnimatedIcons/astral_traveling.gif'; 
import { GET_CUSTOMER_DETAILS } from '../../Customer/Queries/customer';
import { useQuery } from 'react-apollo';
import NoAccess from '../../../GlobalComponents/NoAccess';
import moment from 'moment';
import Loading from '../../../GlobalComponents/Loading';
import { GET_All_GUIDES } from '../../Customer/Queries/customer';
import PrivateMeetingGuideCard from './PrivateMeetingGuideCard';

const Intensively = () => {

    const {data,loading,error,} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const {data:guidesData,loading:lGuides,error:eGuides} = useQuery(GET_All_GUIDES,{
        fetchPolicy: 'network-only'
    });

    var access = false;
    if((data && data.customerContext.membershipTo && !(data.customerContext.lastCompletedCustomerPayment.amount == 0 && moment(data.customerContext.lastCompletedCustomerPayment.dateFrom).add(7,"d").format("YYYY-MM-DD") == moment(data.customerContext.lastCompletedCustomerPayment.dateTo).format("YYYY-MM-DD"))))
        access = true;

    return(

        <div className={"whole-container" + (!access ? " d-flex flex-column justify-content-center align-items-center flex1" : "")}>
            
            {loading || lGuides ?

                <Loading />
            
            :
                (access ?
                    <div className="row m-40">
                        
                        <div className="col-12 col-sm-6 col-xl-4">
                            <div className="service">
                                <div className="icon">
                                    <img src={cardIcon} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("AnnualMembership")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("AnnualMembershipText")}</p>
                                        <p dangerouslySetInnerHTML={{__html:translate.t("AnnualMembershipText2")}}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                            <div className="service">
                                <div className="icon">
                                    <img src={cleanHome} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("CleanAstralHome")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("CleanAstralHomeText")}</p>
                                        <div className="text-center">
                                            <a target="_blank" href="https://laskyplnysvet.cz/blog/35-pravidelna-astralni-ocista-domacnosti" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-6 col-xl-4">
                            <div className="service">
                                <div className="icon">
                                    <img src={astralTraveling} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("Astraltravel")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("AstraltravelText")}</p>
                                        <div className="text-center">
                                            <a target="_blank" href="https://www.astralnicestovani.cz" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                            <div className="service">
                                <div className="icon">
                                    <img src={privateGuided} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("PrivateGuided")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("PrivateGuidedText")}</p>
                                    </div>
                                </div>
                            </div>   
                        </div>
                        
                        <div className="col-12 col-sm-6 col-xl-4">
                            
                            <div className="service">
                                <div className="icon">
                                    <img src={sidrisFamily} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("PrivateSidrisGuided")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("PrivateSidrisGuidedText")}</p>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                            
                            <div className="service">
                                <div className="icon">
                                    <img src={becomeTherapist} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("BecomeTherapist")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("BecomeTherapistText")}</p>
                                        <div className="text-center">
                                            <a target="_blank" href="https://mujterapeut.cz/prace" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>

                        {guidesData.allGuides.map((item) => {

                            var reservationPersonID = 1;
                            var link = "https://mujterapeut.cz/azgarielus";

                            if(item.customerID == 153)
                            {
                                reservationPersonID = 5;
                                link = "https://mujterapeut.cz/ankara";
                            }

                            if(item.customerID == 246)
                            {
                                reservationPersonID = 3;
                                link = "https://mujterapeut.cz/sidris";
                            }

                            if(item.customerID == 237)
                            {
                                reservationPersonID = 4;
                                link = "https://mujterapeut.cz/kler";
                            }

                            return(

                                <div key = {item.customerID} className="col-12 col-sm-6 col-xl-4">
                                                            
                                    <PrivateMeetingGuideCard
                                        customer = {item}
                                        link = {link}
                                        headline = {translate.t("PrivateMeeting") + ": " + item.astralName}
                                        reservationPersonID = {reservationPersonID}
                                        text = {translate.t("PersonalMeeting2Text")}
                                    /> 

                                </div>

                            )
                        })}
                        
                        <div className="col-12 col-sm-6 col-xl-4">
                            
                            <div className="service">
                                <div className="icon">
                                    <img src={together} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("CoCreationWithTeam")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("CoCreationWithTeamText")}</p>
                                        <div className="text-center">
                                            <a target="_blank" href="https://www.astralniakademie.cz/hojnost" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                            
                            <div className="service">
                                <div className="icon">
                                    <img className="individual" src={web} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {translate.t("OwnWebsite")}
                                    </div>
                                    <div className="card-body">
                                        <p>{translate.t("OwnWebsiteText")}</p>
                                        <div className="text-center">
                                            <a target="_blank" href="https://webpro.vladcesily.cz" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                :
                    <NoAccess customer={data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? null : data.customerContext} showFooter = {true} title={translate.t("Intensively")} text={translate.t("IntensivelyNoAccessInfo")} />
                )
            }
        </div>
    )
}

export default Intensively;