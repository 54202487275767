import React,{useState} from 'react';
import {withApollo,useQuery,useMutation } from 'react-apollo';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import {UPDATE_CUSTOMER_PRIVACY,GET_CUSTOMER_DETAILS} from '../Queries/customer';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
import CustomerLib from '../Library/customer';
import Notification from '../../../Library/notification';

const Privacy = (props) => {

    var customerLib = new CustomerLib(props);

    let variables = {
        lang:translate.locale
    }

    const [selectedPrivacy, setSelectedPrivacy] = useState([]);

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
        onCompleted:(data) => {

            if(data.customerContext.customerPrivacy){
                var arr = [];
                for(let val of data.customerContext.customerPrivacy){
                    arr.push(val.privacyID);
                }

                setSelectedPrivacy(arr);
            }
        }
    });

    const [updateCustomerPrivacy,{error:updateError,loading:AddLoading}] = useMutation(UPDATE_CUSTOMER_PRIVACY,{
        onCompleted: (data) => {

            var notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyChanged"),true,false,props.client);
        }

    });

    var err = "";
    if(error || updateError){

        var helper = new Helper();
        err = helper.getApolloErrorText((error || updateError));  

    }

    const in_array = (needle, haystack) => {
        for(var i in haystack) {
            if(haystack[i] == needle) return true;
        }
        return false;
    }

    var showTabsByMaterial = {
        dreams:false,
        sync:false,
        intangibleExperiences:false,
        negativCharacteristics:false,
        positiveCharacteristics:false,
        charismaticEssence:false,
        joys:false,
        transformation:false,
        lifeValues:false,
        meaningOfLife:false,
        wishes:false,
        dreamEightPlusOne:false,
        previousLifes:false,
        actor:false,
        challengeAbundance:false
    }

    if(data){

        for(let i = 0; i < data.customerContext.materials.length;i++){
            if(data.customerContext.materials[i].materialID == 2)showTabsByMaterial.dreams = true;
            if(data.customerContext.materials[i].materialID == 2)showTabsByMaterial.sync = true;
            if(data.customerContext.materials[i].materialID == 2)showTabsByMaterial.intangibleExperiences = true;
    
            if(data.customerContext.materials[i].materialID == 3 || data.customerContext.materials[i].materialID == 89)showTabsByMaterial.negativCharacteristics = true;
            if(data.customerContext.materials[i].materialID == 4 || data.customerContext.materials[i].materialID == 89)showTabsByMaterial.positiveCharacteristics = true;
    
            if(data.customerContext.materials[i].materialID == 9)showTabsByMaterial.charismaticEssence = true;
            if(data.customerContext.materials[i].materialID == 9)showTabsByMaterial.joys = true;
    
            if(data.customerContext.materials[i].materialID == 10)showTabsByMaterial.transformation = true;
            if(data.customerContext.materials[i].materialID == 11)showTabsByMaterial.lifeValues = true;
    
            if(data.customerContext.materials[i].materialID == 12)showTabsByMaterial.meaningOfLife = true;
            if(data.customerContext.materials[i].materialID == 12)showTabsByMaterial.wishes = true;
    
            if(data.customerContext.materials[i].materialID == 15)showTabsByMaterial.dreamEightPlusOne = true;
            if(data.customerContext.materials[i].materialID == 84)showTabsByMaterial.previousLifes = true;
            if(data.customerContext.materials[i].materialID == 39)showTabsByMaterial.actor = true;
            if(data.customerContext.materials[i].materialID == 186)showTabsByMaterial.challengeAbundance = true;
        }

    }

    return(
        <div>

            {err ?
                <Error text = {err} />
            :
                (loading ?
                    <Loading />
                :
                    <div className="whole-container">

                        <div className="card main">
										
                            <div className="card-header d-flex align-items-center">
                                {translate.t('Privacy')}
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => updateCustomerPrivacy({
                                    variables:{
                                        privacyID:selectedPrivacy
                                    }
                                })}>{translate.t('SaveChanges')}</button>
                            </div>
                            <div className="card-body">

                                <p dangerouslySetInnerHTML={{__html:translate.t('AllowCustomerPrivacy')}}></p>
                                <p>{translate.t('AllowCustomerPrivacy2')}</p>
   
                            </div>
                            
                        </div>

                        <div className="row">

                            <div className="col-12 col-sm-6">

                                <div className="card">
                                    <div className="card-header">{translate.t("Daily")}</div>
                                    <div className="card-body">
                                        <ul className="no-dots">

                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-18" value={18} type="checkbox" checked = {in_array(18,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(18,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-18" className="form-check-label d-flex align-items-center" >
                                                                <strong>{translate.t("MyMotivation")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>

                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-1" value={1} type="checkbox" checked = {in_array(1,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(1,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-1" className="form-check-label d-flex align-items-center" >
                                                                <strong>{translate.t("Messages")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-22" value={1} type="checkbox" checked = {in_array(22,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(22,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-22" className="form-check-label d-flex align-items-center" >
                                                                <strong>{translate.t("MyProgress")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>

                                            {showTabsByMaterial.dreams ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-2" value={2} type="checkbox"  checked = {in_array(2,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(2,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-2" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("Dreams")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                </li>
                                            :null}
                                            {showTabsByMaterial.sync ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-3" value={3} type="checkbox" checked = {in_array(3,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(3,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-3" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("Sync")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                </li>
                                            :null}
                                            {showTabsByMaterial.intangibleExperiences ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-4" value={4} type="checkbox" checked = {in_array(4,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(4,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-4" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("IntangibleExperiences")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                </li>
                                            :null}
                                            {showTabsByMaterial.negativCharacteristics ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-5" value={5} type="checkbox" checked = {in_array(5,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(5,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-5" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("NegativCharacteristics")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                </li>
                                            :null}
                                            {showTabsByMaterial.positiveCharacteristics ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-6" value={6} type="checkbox" checked = {in_array(6,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(6,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-6" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("PositiveCharacteristics")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                </li>
                                            :null}
                                            {showTabsByMaterial.charismaticEssence ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-7" value={7} type="checkbox" checked = {in_array(7,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(7,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-7" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("CharismaticEssence")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>  
                                                </li>
                                            :null}
                                            {showTabsByMaterial.joys ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-8" value={8} type="checkbox" checked = {in_array(8,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(8,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-8" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("30Joys")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>  
                                                </li>
                                            :null}
                                            {showTabsByMaterial.transformation ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-19" value={19} type="checkbox" checked = {in_array(19,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(19,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-19" className="form-check-label d-flex align-items-center" >
                                                                <strong>{translate.t("Transformation")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>   
                                                </li>
                                            :null}
                                            {showTabsByMaterial.lifeValues ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-9" value={9} type="checkbox" checked = {in_array(9,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(9,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-9" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("LifeValues")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>   
                                                </li>
                                            :null}
                                            {showTabsByMaterial.meaningOfLife ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-10" value={10} type="checkbox" checked = {in_array(10,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(10,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-10" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("meaningOfLife")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>   
                                                </li>
                                            :null}
                                            {showTabsByMaterial.wishes ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-11" value={11} type="checkbox" checked = {in_array(11,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(11,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-11" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("108wishes")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>   
                                                </li>
                                            :null}
                                            {showTabsByMaterial.dreamEightPlusOne ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-12" value={12} type="checkbox" checked = {in_array(12,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(12,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-12" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("8+1dream")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>   
                                                </li>
                                            :null}
                                            {showTabsByMaterial.actor ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-13" value={13} type="checkbox" checked = {in_array(13,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(13,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-13" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("Actor")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>  
                                                </li>
                                            :null}

                                            {showTabsByMaterial.previousLifes ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-21" value={21} type="checkbox" checked = {in_array(21,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(21,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-21" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("PreviousLifes")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>  
                                                </li>
                                            :null}

                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-20" value={20} type="checkbox" checked = {in_array(20,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(20,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-20" className="form-check-label d-flex align-items-center" >
                                                                <strong>{translate.t("PersonalNotes")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>  
                                            </li>

                                            {showTabsByMaterial.challengeAbundance ?
                                                <li className="mb-2">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input id="p-24" value={24} type="checkbox" checked = {in_array(24,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(24,selectedPrivacy, setSelectedPrivacy)} />
                                                            <label htmlFor="p-24" className="form-check-label d-flex align-items-center" >
                                                                    <strong>{translate.t("ChallengeAbundance")}</strong>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>  
                                                </li>
                                            :null}

                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-sm-6">

                                <div className="card">
                                    <div className="card-header">{translate.t("PersonalDetails")}</div>
                                    <div className="card-body">
                                        <ul className="no-dots">

                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-14" value={14} type="checkbox" checked = {in_array(14,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(14,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-14" className="form-check-label d-flex align-items-center" >
                                                                <strong>{translate.t("ActualJob")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-15" value={15} type="checkbox" checked = {in_array(15,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(15,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-15" className="form-check-label d-flex align-items-center" >
                                                            <strong>{translate.t("IdealJob")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-16" value={16} type="checkbox" checked = {in_array(16,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(16,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-16" className="form-check-label d-flex align-items-center" >
                                                            <strong>{translate.t("IamGoodAt")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>
                                            <li className="mb-2">
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input id="p-17" value={17} type="checkbox" checked = {in_array(17,selectedPrivacy)} className="form-check-input" onChange={() => customerLib.setPrivacy(17,selectedPrivacy, setSelectedPrivacy)} />
                                                        <label htmlFor="p-17" className="form-check-label d-flex align-items-center" >
                                                            <strong>{translate.t("AboutMe")}</strong>
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                    
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                    </div>
                
                )
            
            }

        </div>
    )

}

export default withApollo(Privacy);